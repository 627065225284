import { getCurrentSessionUtm, setGoogleAdwordsInfo } from '../utils/helper';
import { eventFormSubmission } from './analytics/ga4CustomEvents';
import { getQueryFromStorage } from '../hooks/useQueryCatcher';
import TagManager from 'react-gtm-module';
import { useCookies } from 'react-cookie';
import Cookies from 'js-cookie';
import { getStoredUtmParameters } from '../utils/utmTracking';

const CRM_URL = '/api/contact';
const EMAIL_URL = '/api/contact/email';
const FB_URL = '/api/crm/fb-conversion';
const PINTEREST_URL = '/api/crm/pint-conversion';

export const submitForm = async (values, options = {}) => {
  console.log('===== Form submission started =====');
  
  try {
    const { formId, locationContext, isIOS } = options;
    console.log('Form options:', { formId });
    
    // Basic validation
    if (!values || !values.email) {
      console.error('Missing required form fields');
      return { success: false, error: 'Missing required form fields' };
    }
    
    // Create a simple basic data object
    const baseData = {
      email: values.email,
      phone: values.phone || '',
      first_name: values.first_name || values.full_name?.split(' ')[0] || '',
      last_name: values.last_name || (values.full_name?.split(' ').slice(1).join(' ') || ''),
      client: 'classyclosets',
      form_id: formId || 'consultation-form',
      message: values.message || '',
      tenantId: values.tenantId || locationContext?.tenantId || '',
      city: locationContext?.city || '',
      state: locationContext?.state || '',
      showroom: values.showroom || ''
    };
    
    // Add stored UTM parameters
    if (values.stored_utm_data) {
      try {
        // Use the UTM data passed from the form
        baseData.stored_utm_data = values.stored_utm_data;
        console.log('Using UTM data from form values:', values.stored_utm_data);
      } catch (utmError) {
        console.error('Error processing UTM data from form:', utmError);
      }
    } else {
      // Try to get UTM parameters directly as a fallback
      try {
        const storedUtmData = getStoredUtmParameters();
        if (Object.keys(storedUtmData).length > 0) {
          baseData.stored_utm_data = JSON.stringify(storedUtmData);
          console.log('Added UTM parameters from storage:', storedUtmData);
        }
      } catch (utmError) {
        console.error('Error getting UTM parameters from storage:', utmError);
      }
    }
    
    console.log('Sending base data to API endpoints');
    
    // Simplified API calls approach - sequential for reliability
    let crmResult = null;
    let emailResult = null;
    let fbResult = null;
    let pinterestResult = null;
    
    // Step 1: Always do CRM submission
    try {
      console.log('Calling CRM API');
      const crmResponse = await fetch(CRM_URL, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(baseData)
      });
      crmResult = await crmResponse.json();
      console.log('CRM API response received');
    } catch (crmError) {
      console.error('CRM submission error:', crmError);
      crmResult = { error: crmError.message };
    }
    
    // Step 2: Always do email notification
    try {
      console.log('Calling Email API');
      const emailResponse = await fetch(EMAIL_URL, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(baseData)
      });
      emailResult = await emailResponse.json();
      console.log('Email API response received');
    } catch (emailError) {
      console.error('Email submission error:', emailError);
      emailResult = { error: emailError.message };
    }
    
    // Step 3: Do analytics for new submissions
    try {
      console.log('Sending analytics events');
      // GTM
      TagManager.dataLayer({
        dataLayer: {
          crmOrClassic: "classic",
          formId: formId
        },
      });
      
      // GA4
      const eventParams = {
        email: values.email,
        phone: values.phone,
        crmOrClassic: "classic",
        page_location: window.location.href,
        page_title: document.title,
        formId: formId
      };
      await eventFormSubmission(window.location.href, "lead", eventParams);
      console.log('Analytics events sent');
    } catch (analyticsError) {
      console.error('Analytics error (continuing anyway):', analyticsError);
    }
    
    // Step 4: Facebook conversion for new submissions
    try {
      console.log('Calling Facebook conversion API');
      const fbResponse = await fetch(FB_URL, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          first_name: values.full_name || baseData.first_name,
          email: values.email,
          phone: values.phone,
          event_time: Date.now()
        })
      });
      fbResult = await fbResponse.json();
      console.log('Facebook API response received');
    } catch (fbError) {
      console.error('Facebook conversion error (continuing anyway):', fbError);
      fbResult = { error: fbError.message };
    }
    
    // Step 5: Pinterest conversion for new submissions
    try {
      console.log('Calling Pinterest conversion API');
      const pinterestResponse = await fetch(PINTEREST_URL, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          first_name: baseData.first_name,
          last_name: baseData.last_name,
          email: values.email,
          phone: values.phone,
          event_time: Date.now()
        })
      });
      pinterestResult = await pinterestResponse.json();
      console.log('Pinterest API response received');
    } catch (pinterestError) {
      console.error('Pinterest conversion error (continuing anyway):', pinterestError);
      pinterestResult = { error: pinterestError.message };
    }
    
    console.log('===== Form submission completed successfully =====');
    
    // Return success with all data for new submissions
    return {
      success: true,
      alreadySubmitted: false,
      timestamp: new Date().toISOString(),
      formData: values,
      crmResponse: crmResult,
      emailResponse: emailResult,
      fbResponse: fbResult,
      pinterestResponse: pinterestResult
    };
  } catch (error) {
    console.error('===== CRITICAL ERROR in form submission =====', error);
    return {
      success: false,
      error: error.message,
      timestamp: new Date().toISOString()
    };
  }
}; 