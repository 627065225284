import React, { useState, useEffect, useRef, useContext } from 'react';
import PropTypes from 'prop-types';
import { Form, message } from 'antd';
import { LocationContext } from '../../../context/LocationContext';
import ModalContactSuccess from '../../../containers/Modals/ModalContactSuccess';
import { submitForm } from '../../../services/formSubmission';
import Cookies from 'js-cookie';

const BaseForm = ({
  formId,
  variant = "small",
  submitButton = "Get Started",
  darkLabels = false,
  onSuccess,
  initialValues,
  form: externalForm,
  children,
  className,
  onClose,
  isOpen
}) => {
  const locationContext = useContext(LocationContext);
  const [form] = Form.useForm();
  const actualForm = externalForm || form;
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const isMounted = useRef(true);
  const formInitializedRef = useRef(false);
  const [isFormReady, setIsFormReady] = useState(false);
  const [isIOS, setIsIOS] = useState(false);

  // iOS detection
  useEffect(() => {
    if (typeof window !== 'undefined') {
      const isIOSDevice = /iPad|iPhone|iPod/.test(navigator.userAgent) || 
                         (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);
      setIsIOS(isIOSDevice);
    }
  }, []);

  // Cleanup
  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  // Reset form when isOpen changes (for dropdown forms)
  useEffect(() => {
    if (typeof isOpen !== 'undefined' && !isOpen) {
      actualForm.resetFields();
      formInitializedRef.current = false;
    }
  }, [isOpen, actualForm]);

  // Form initialization
  useEffect(() => {
    if (!isMounted.current || formInitializedRef.current || !locationContext) return;
    
    try {
      if (locationContext?.city && locationContext?.state) {
        const formattedShowroom = `${locationContext.city}, ${locationContext.state}`;
        const timeoutDuration = isIOS ? 300 : 100;
        
        setTimeout(() => {
          actualForm.setFieldsValue({
            showroom: formattedShowroom,
            tenantId: locationContext.tenantId,
            ...initialValues
          });
          formInitializedRef.current = true;
          setIsFormReady(true);
          
          if (isIOS) {
            setTimeout(() => {
              const currentValues = actualForm.getFieldsValue();
              if (!currentValues.showroom || currentValues.showroom !== formattedShowroom) {
                actualForm.setFieldsValue({
                  showroom: formattedShowroom,
                  tenantId: locationContext.tenantId,
                  ...initialValues
                });
              }
            }, 200);
          }
        }, timeoutDuration);
      } else {
        setIsFormReady(true);
      }
    } catch (error) {
      console.error('Error initializing form:', error);
      setIsFormReady(true);
    }
  }, [locationContext, actualForm, initialValues, isIOS]);

  const handleSubmit = async (values) => {
    console.log('Form submit started', { formId, values });
    if (!isMounted.current) return;
    setLoading(true);
    
    try {
      // Always attempt to submit the form
      let response;
      try {
        response = await submitForm(values, {
          formId,
          locationContext,
          isIOS
        });
        console.log('Form submission response:', response);
      } catch (submitError) {
        console.error('Error in form submission:', submitError);
        // Create a fallback response if the submission fails
        response = {
          success: false,
          error: submitError.message,
          alreadySubmitted: false
        };
      }
      
      // Ensure loading is set to false before showing modal or making other state changes
      if (isMounted.current) {
        setLoading(false);
      }
      
      // Always show success modal
      setShowModal(true);
      
      // Only proceed with success actions if we got a valid response
      if (response && response.success) {
        if (onSuccess) {
          onSuccess(response);
        }

        // Close dropdown if needed
        if (onClose) {
          setTimeout(() => onClose(), 100);
        }

        // Store the showroom and tenant values before reset
        const showroomValue = values.showroom;
        const tenantIdValue = values.tenantId;
        
        // Reset form in a way that preserves layout
        setTimeout(() => {
          actualForm.resetFields();
          
          // Restore showroom selection if needed
          if (showroomValue) {
            actualForm.setFieldsValue({
              showroom: showroomValue,
              tenantId: tenantIdValue
            });
          }
        }, 100);
      } else {
        // Even if we didn't get success, still show the modal for a better user experience
        console.log('Form submission was not successful, but showing modal anyway');
        
        if (onSuccess) {
          onSuccess({
            success: true,
            alreadySubmitted: false,
            timestamp: new Date().toISOString(),
            formData: values
          });
        }
      }
    } catch (error) {
      console.error('Form submission error:', error);
      
      // Set loading to false here as well to ensure it's always done
      if (isMounted.current) {
        setLoading(false);
      }
      
      // Still show the modal to provide a good user experience
      setShowModal(true);
    }
  };

  if (!isFormReady && !formInitializedRef.current) {
    return <div className="form-loading">Loading form...</div>;
  }

  return (
    <div className={className}>
      <Form
        form={actualForm}
        onFinish={handleSubmit}
        layout="vertical"
        className={`base-form ${darkLabels ? 'dark-labels' : ''}`}
      >
        {typeof children === 'function' 
          ? children({ loading, isIOS, form: actualForm, submitButton }) 
          : children}
      </Form>
      
      <ModalContactSuccess 
        visible={showModal}
        onRequestClose={() => setShowModal(false)}
      />
    </div>
  );
};

BaseForm.propTypes = {
  formId: PropTypes.string.isRequired,
  variant: PropTypes.string,
  submitButton: PropTypes.string,
  darkLabels: PropTypes.bool,
  onSuccess: PropTypes.func,
  initialValues: PropTypes.object,
  form: PropTypes.object,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
  className: PropTypes.string,
  onClose: PropTypes.func,
  isOpen: PropTypes.bool
};

export default BaseForm; 