import React from 'react';
import PropTypes from 'prop-types';
import FormConsultationSmall from './small';
import FormConsultationLarge from './large';
import { getStoredUtmParameters } from '../../../utils/utmTracking';

const FormConsultation = ({ 
  variant = "small",
  submitButton = "Get Started",
  formId,
  darkLabels = false,
  onSuccess,
  showModal,
  setShowModal,
  initialValues,
  form,
  isFormReady = true,
  isOpen,
  onClose
}) => {
  const variants = ["small", "large"];
  variant = variants.includes(variant) ? variant : variants[0];

  const FormComponent = variant === "small" ? FormConsultationSmall : FormConsultationLarge;

  const onFinish = (values) => {
    setLoading(true);
    setError(null);
    
    // Add visitor ID from fingerprint.js if available
    if (typeof window !== 'undefined' && window.uniqueVisitorId) {
      values.visitorId = window.uniqueVisitorId;
      values.confidenceScore = window.confidenceScore || "unknown";
    }
    
    // Add stored UTM parameters from cookies/localStorage
    const storedUtmData = getStoredUtmParameters();
    if (Object.keys(storedUtmData).length > 0) {
      console.log('Adding stored UTM parameters to form submission:', storedUtmData);
      values.stored_utm_data = JSON.stringify(storedUtmData);
    }
    
    // Continue with form submission logic
    // ... existing code ...
  };

  return (
    <FormComponent
      formId={formId}
      darkLabels={darkLabels}
      submitButton={submitButton}
      initialValues={initialValues}
      form={form}
      isOpen={isOpen}
      onClose={onClose}
      onSuccess={onSuccess}
    />
  );
};

FormConsultation.propTypes = {
  variant: PropTypes.string,
  submitButton: PropTypes.string,
  formId: PropTypes.string,
  darkLabels: PropTypes.bool,
  onSuccess: PropTypes.func,
  showModal: PropTypes.bool,
  setShowModal: PropTypes.func,
  initialValues: PropTypes.object,
  form: PropTypes.object,
  isFormReady: PropTypes.bool,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func
};

export default FormConsultation;
