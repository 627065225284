import React, { useState, useEffect, useContext, useMemo, useRef } from "react";
import { Form, Input, Row, Col } from "antd";
import { FormContainer, FormItem, StyledInput, StyledTextArea, StyledSelect, StyledLabel, PButton } from "./styled";
import Loading from "../../../components/Loading";
import ModalContactSuccess from "../../Modals/ModalContactSuccess";
import Image from 'next/image';
import { EEAT, EEATWRAP, EEATText } from "./styled";
import { jsonData } from "../../../components/LocationsMap/_data";
import { LocationContext } from "../../../context/LocationContext";
import BaseForm from '../../../components/Forms/BaseForm';
import PropTypes from 'prop-types';

const CRM_URL = "/api/contact";

const FormConsultationSmall = ({
  darkLabels,
  submitButton = "Get Started",
  formId = "consultation-small",
  initialValues,
  form: externalForm,
  isOpen,
  onClose,
  onSuccess
}) => {
  const showroomOptions = React.useMemo(() => {
    try {
      return jsonData.features
        .filter(location => {
          if (location.properties.dealer === 'yes') return false;
          return location.properties.city && location.properties.state;
        })
        .map(location => {
          const city = location.properties.city.trim();
          const state = location.properties.state.trim();
          return {
            value: `${city}, ${state}`,
            label: `${city}, ${state}`,
            data: {
              ...location,
              properties: {
                ...location.properties,
                city,
                state
              }
            }
          };
        })
        .sort((a, b) => a.label.localeCompare(b.label));
    } catch (error) {
      console.error('Error creating showroom options:', error);
      return [];
    }
  }, []);

  return (
    <BaseForm
      formId={formId}
      variant="small"
      darkLabels={darkLabels}
      submitButton={submitButton}
      initialValues={initialValues}
      form={externalForm}
      isOpen={isOpen}
      onClose={onClose}
      onSuccess={onSuccess}
    >
      {({ loading, form }) => (
        <FormContainer 
          darkLabels={darkLabels}
          $backgroundColor={darkLabels ? 'transparent' : 'rgba(118, 39, 56, 1)'}
        >
          <FormItem
            name="full_name"
            rules={[{ required: true, message: 'Please enter your name' }]}
            label="Full Name"
          >
            <StyledInput placeholder="Full Name" />
          </FormItem>

          <FormItem
            name="email"
            rules={[
              { required: true, message: 'Please enter your email' },
              { type: 'email', message: 'Please enter a valid email' }
            ]}
            label="Email"
          >
            <StyledInput placeholder="Email" />
          </FormItem>

          <FormItem
            name="phone"
            rules={[{ required: true, message: 'Please enter your phone number' }]}
            label="Phone"
          >
            <StyledInput placeholder="Phone" />
          </FormItem>

          <FormItem
            name="showroom"
            rules={[{ required: true, message: 'Please select a showroom' }]}
            label="Select Showroom"
          >
            <StyledSelect
              placeholder="Select Showroom"
              options={showroomOptions}
              showSearch
              filterOption={(input, option) =>
                option.label.toLowerCase().includes(input.toLowerCase())
              }
            />
          </FormItem>

          <FormItem 
            name="message"
            label="Message (Optional)"
          >
            <StyledTextArea
              placeholder="Message (Optional)"
              autoSize={{ minRows: 4, maxRows: 6 }}
            />
          </FormItem>

          <FormItem name="tenantId" hidden>
            <Input type="hidden" />
          </FormItem>

          <div style={{ position: 'relative', marginTop: '16px' }}>
            <PButton
              type="primary"
              htmlType="submit"
              className="submit-button"
              disabled={loading}
            >
              {submitButton}
            </PButton>
            
            {loading && (
              <div style={{
                position: 'absolute',
                left: '35%',
                top: '50%',
                transform: 'translateY(-50%)',
                display: 'flex',
                alignItems: 'center'
              }}>
                <div className="ant-btn-loading-icon">
                  <span role="img" aria-label="loading" className="anticon anticon-loading anticon-spin">
                    <svg viewBox="0 0 1024 1024" focusable="false" data-icon="loading" width="1em" height="1em" fill="currentColor" aria-hidden="true">
                      <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
                    </svg>
                  </span>
                </div>
              </div>
            )}
          </div>
        </FormContainer>
      )}
    </BaseForm>
  );
};

FormConsultationSmall.propTypes = {
  darkLabels: PropTypes.bool,
  submitButton: PropTypes.string,
  formId: PropTypes.string,
  initialValues: PropTypes.object,
  form: PropTypes.object,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onSuccess: PropTypes.func
};

export default FormConsultationSmall;
